<template>
  <div id="newsStoryContent">
    <p id="Text1">
      You’ve asked for it, and so now you’re getting it: more space for your stuff!  Dr. Overbuild has been working non-stop on a new Vault and Safe system!  This new storage system will allow you to safely store away extra items into your own private Vault in Nexus Tower.  The Darkitect would love to get his hands on the contents of the Vaults, but never fear!  The Sentinels are standing guard to make sure only YOU can access your personal Vault!
    </p>
    <center>
      <!-- pic18856AC8EA0546EE57FC886065996B9B.jpg -->
      <img src="@/assets/news-network/nexus-tower-3.jpg" class="rounded" style="width: 440px; height: 261px;">
    </center>
    <p>
      Before you can access the Vault system, you will need to first speak to Nexus Naomi, Nexus Tower’s Artificial Intelligence.  She will guide you to meet your Faction Leader, who will give you a series of missions.  After you receive approval from your Faction Leader to use the Vault system, find Nexus Naomi near the Sentinel Faction HQ.  She will help you open up your Vault account –and has a complimentary toaster model for you too!
    </p>
    <center>
      <!-- picDFE0D1AA69CD7BFA6761DAFB3BA275F3.jpg -->
      <img src="@/assets/news-network/nexus-tower-2.jpg" class="rounded" style="width: 440px; height: 261px;">
    </center>
    <p>
      The standard Vault size begins with 40 slots for general items (like gear or consumables) and 40 slots for your models.  If you find yourself needing even more space, talk to Nexus Naomi again, who will give you some Missions to expand the size of your Vault!  You can expand your Vault up to <b>240</b> slots for general items and 240 for your models!
    </p>
    <p>
      That’s not all!  Complete even more Nexus Tower Missions, and you will be able to access a special Safe on all of your claimed Properties!  Your Safe will allow you to access your Vault and all of the items inside.  Property visitors will be able to see the Safe on your Property, but only you will be able to use your Safe on your claimed Properties!
    </p>
    <center>
      <!-- picC1501A4505A85ACCB3DEE7289DD890A6.jpg -->
      <img src="@/assets/news-network/property-safe.jpg" class="rounded" style="width: 440px; height: 261px;">
    </center>
    <p>
      Keep up your Brick Donations to complete the construction of Nexus Tower.  When the Tower is done, you’ll be able to finally find a place for all of that extra stuff you’ve been carrying around.  And this is only the beginning!
    </p>
    <p>
      Discuss this article on the
      <router-link to="/messageboards/3759788">message boards</router-link>!
    </p>
 </div>
</template>
